<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-tree"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Logs da importação #{{ importacao.id }}
        </div>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            style="float: right !important"
            class="mt-n9"
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              id="importacao-dados-logs-tecnicos"
              icon
              color="black"
              @click="baixarLogsTecnicos()"
              :loading="loadingLogsTecnicos"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="22"> mdi-download-circle-outline </v-icon>
            </v-btn>
          </div>
        </template>
        Clique aqui para baixar os logs técnicos
      </v-tooltip>
      <v-divider class="my-4" />
      <span id="importacao-dados-logs-first-sec">
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1"> Título da importação: </label>
            <v-label>{{ importacao.titulo }}</v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1"> Empresa: </label>
            <v-label>{{ importacao.empresa }}</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1"> Descrição: </label>
            <v-label>
              {{ importacao.descricao ? importacao.descricao : '-' }}
            </v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1"> Criada por: </label>
            <v-label>
              {{ importacao.usuario }} em
              {{ importacao.criada_em | parseToDateTimeBR }}
            </v-label>
          </v-col>
        </v-row>
      </span>
      <v-divider class="mt-2 mb-2" />
      <v-row>
        <v-col cols="12">
          <import-status-card
            :arquivos="importacao.arquivos"
            :status="importacao.status"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2 mb-2" />
      <v-row>
        <v-col cols="12">
          <tabela-arquivos-xml
            :arquivos="importacao.arquivos"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import ImportacoesDadosIndqualXmlService from '@/services/ImportacoesDadosIndqualXmlService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import ImportStatusCard from '@/components/dados/importacoes-xml/ImportStatusCard.vue';
import TabelaArquivosXml from '@/components/dados/importacoes-xml/TabelaArquivosXml.vue';

export default {
  mixins: [refreshDataMixins],
  components: {
    ImportStatusCard,
    TabelaArquivosXml
  },
  data: () => ({
    importacao: {
      empresa: {},
      usuario: {}
    },
    loading: false,
    loadingLogs: false,
    loadingLogsTecnicos: false
  }),
  async created() {
    await this.refreshData(this.getLogsImportacao);
  },
  methods: {
    getLogsImportacao() {
      if (!this.$route.params.id) {
        return;
      }
      this.loading = true;
      ImportacoesDadosIndqualXmlService.getLogsImportacao(this.$route.params.id)
        .then((response) => {
          this.importacao = response.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.loading = false));
    },
    baixarLogsTecnicos() {
      this.loadingLogsTecnicos = true;
      ImportacoesDadosIndqualXmlService.baixarLogsTecnicos(this.importacao.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-tecnicos-importacao-${this.importacao.id}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs técnicos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsTecnicos = false));
    }
  }
};
</script>
