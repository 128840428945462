<template>
  <div>
    <v-text-field
      v-model="search"
      v-if="!loading"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 mb-4 break-search"
      label="Procurar"
      hide-details
      single-line
    />
    <v-data-table
      :loading="loading"
      :hide-default-header="loading"
      :headers="[
        {
          text: 'Arquivo XML',
          value: 'arquivo_xml'
        },
        {
          text: 'Data de Geração',
          value: 'data_geracao_arquivo'
        },
        {
          text: 'Sequencial',
          value: 'sequencial'
        },
        {
          text: 'N° de Indicadores',
          value: 'num_indicadores_arquivo'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]"
      :items="arquivos"
      :search.sync="search"
      :sort-by="['data_geracao_arquivo', 'sequencial']"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:[`item.arquivo_xml`]="{ item }">
        <a
          title="Baixar arquivo XML"
          @click="() => baixarArquivoXml(item)"
        >
          {{ item.nome }}
        </a>
      </template>
      <template v-slot:[`item.data_geracao_arquivo`]="{ item }">
        {{ item.data_geracao_arquivo | parseToDateBR }}
      </template>
      <template v-slot:[`item.num_indicadores_arquivo`]="{ item }">
        {{ item.num_indicadores_arquivo | parseNumberToIntegerBR }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <general-status :status="item.status" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ArquivoXmlIndqualService from '@/services/ArquivoXmlIndqualService';

export default {
  name: 'TabelaArquivosXml',
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },
  props: {
    arquivos: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: ''
  }),
  methods: {
    baixarArquivoXml(arquivo) {
      ArquivoXmlIndqualService.baixarArquivo(arquivo.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', arquivo.nome);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download do arquivo XML.', '', {
            position: 'topRight'
          });
        });
    }
  }
};
</script>
