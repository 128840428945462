var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('v-text-field',{staticClass:"ml-auto mt-4 mb-4 break-search",attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.loading,"hide-default-header":_vm.loading,"headers":[
      {
        text: 'Arquivo XML',
        value: 'arquivo_xml'
      },
      {
        text: 'Data de Geração',
        value: 'data_geracao_arquivo'
      },
      {
        text: 'Sequencial',
        value: 'sequencial'
      },
      {
        text: 'N° de Indicadores',
        value: 'num_indicadores_arquivo'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],"items":_vm.arquivos,"search":_vm.search,"sort-by":['data_geracao_arquivo', 'sequencial'],"disable-pagination":"","hide-default-footer":""},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.arquivo_xml",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"title":"Baixar arquivo XML"},on:{"click":function () { return _vm.baixarArquivoXml(item); }}},[_vm._v(" "+_vm._s(item.nome)+" ")])]}},{key:"item.data_geracao_arquivo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateBR")(item.data_geracao_arquivo))+" ")]}},{key:"item.num_indicadores_arquivo",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.num_indicadores_arquivo))+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }